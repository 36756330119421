import React, { Component } from "react";
import { translate } from "react-i18next";
import Equalizer from "react-equalizer";
import FreamScroll from "shared/fream-scroll";
import OwlCarousel from "react-owl-carousel2";
import IconArrowSmallLeft from 'static/home/icons/arrow-small-left.svg';
import IconArrowSmallRight from 'static/home/icons/arrow-small-right.svg';
import "./index.scss";

class Carousel extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const { t, items, className} = this.props,
      isIE11 = !!window.MSInputMethodContext && !!document.documentMode,
      carouselVisible = !isIE11,
      firstSlide = (items && items.length && <div
        className="carousel__slide"
      >
        <img className="carousel__img" src={items[0].image} alt={items[0].title} />
        <h4 className="carousel__title" dangerouslySetInnerHTML={{ __html: items[0].title }} />
        <p className="carousel__description" dangerouslySetInnerHTML={{ __html: items[0].content }} />
      </div>),
      containerClassName = 'carousel' + (className ? ' ' + className : '');

    const options = {
      items: 1,
      nav: false,
      dots: false,
      rewind: true,
      autoplay: false,
      loop: true,
    };

    return (
      <div className="carousel-container">
        <section className={containerClassName}>
          {carouselVisible && <div className="carousel__arrows">
            <div className="prev" onClick={() => this.refs.homecarousel.prev()}>
              <IconArrowSmallLeft />
            </div>
            <div className="next" onClick={() => this.refs.homecarousel.next()}>
              <IconArrowSmallRight />
            </div>
          </div>}
          {carouselVisible && <OwlCarousel
            className="carousel__slides owl-carousel"
            ref="homecarousel"
            options={options}
          >
            {items.map(item => {
              return (
                <div
                  className="carousel__slide"
                  data-slide={item.id}
                  key={item.id}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <img className="carousel__img" src={item.image} alt={item.title} />
                  <h4 className="carousel__title" dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p className="carousel__description" dangerouslySetInnerHTML={{ __html: item.content }} />
                  <p className="carousel__description" dangerouslySetInnerHTML={{ __html: item.content2 }} />
                </div>
              );
            })}
          </OwlCarousel>}
          {!carouselVisible && firstSlide}
        </section>
      </div>
    );
  }
}

export default Carousel;
