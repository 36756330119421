import React, { Component } from 'react';
import { translate } from "react-i18next";
import "./index.scss";
import MetaTags from 'react-meta-tags';
import LogotypeStrip from 'components/logotypesStrip';
import Carousel from './components/carousel';
import SlideShow from './components/trainingsSlideShow';
import { actions as uiActions, ModalTypes } from 'shared/redux/ui';
import { selectors as authSelectors } from 'shared/redux/auth';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import AssetHelper from 'AssetHelper';
import i18next from 'i18next';
import TabsComponent from './components/tabsComponent';
import Tabs from './components/tabs';
import Iframe from 'react-iframe';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const wrapperStyle = { width: 400, margin: 50 };

class Trainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideTab: false
    };
    this.onResizeUpdate = this.onResizeUpdate.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.onResizeUpdate.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeUpdate.bind(this));
  }
  onResizeUpdate() {
    if (window.innerWidth < 1372) {
      this.setState({ hideTabs: true });
    } else {
      this.setState({ hideTabs: false });
    }
  }
  render() {
    const { t, user } = this.props,
      { hideTabs } = this.state,

      elementMy = {
      content: `<span>${t('trainings.investmentDesc12')}</span>`
      },

      carousel2Items = [
        {
          title: t("trainings.investmentHeading1"),
          content: `<span>${t('trainings.investmentDesc1')}</span>`,
          content2: `<span>${t('trainings.investmentDesc12')}</span>`,
          image: AssetHelper.staticPath('/static/home/trainings/job-training.jpg'),
          id: 1
        },
        {
          title: t("trainings.investmentHeading2"),
          content: `<span>${t('trainings.investmentDesc2')}</span>`,
          content2: `<span>${t('trainings.investmentDesc22')}</span>`,
          image: AssetHelper.staticPath('/static/home/trainings/onboarding.jpg'),
          id: 2
        },
        {
          title: t("trainings.investmentHeading3"),
          content: `<span>${t('trainings.investmentDesc3')}</span>`,
          content2: `<span>${t('trainings.investmentDesc32')}</span>`,
          image: AssetHelper.staticPath('/static/home/trainings/section-of-production-tape.jpg'),
          id: 3
        },
        {
          title: t("trainings.investmentHeading4"),
          content: `<span>${t("trainings.investmentDesc4")}</span>`,
          content2: `<span>${t("trainings.investmentDesc42")}</span>`,
          image: AssetHelper.staticPath('/static/home/trainings/preparing-for-working.jpg'),
          id: 4
        },
        {
          title: t("trainings.investmentHeading5"),
          content: `<span>${t("trainings.investmentDesc5")}</span>`,
          content2: `<span>${t("trainings.investmentDesc52")}</span>`,
          image: AssetHelper.staticPath('/static/home/trainings/safety-trainings.jpg'),
          id: 5
        },
        {
          title: t("trainings.investmentHeading6"),
          content: `<span>${t("trainings.investmentDesc6")}</span>`,
          content2: `<span>${t("trainings.investmentDesc62")}</span>`,
          image: AssetHelper.staticPath('/static/home/trainings/tutorials.jpg'),
          id: 6
        }
      ],
      tabs = [
        {
          label: t('trainings.investmentHeading1'),
          description: t('trainings.investmentDesc1'),
          description2: t('trainings.investmentDesc12'),
          img: AssetHelper.staticPath('/static/home/trainings/job-training.jpg'),
        },
        {
          label: t('trainings.investmentHeading2'),
          description: t('trainings.investmentDesc2'),
          description2: t('trainings.investmentDesc22'),
          img: AssetHelper.staticPath('/static/home/trainings/onboarding.jpg'),
        },
        {
          label: t('trainings.investmentHeading3'),
          description: t('trainings.investmentDesc3'),
          description2: t('trainings.investmentDesc32'),
          img: AssetHelper.staticPath('/static/home/trainings/section-of-production-tape.jpg'),
        },
        {
          label: t('trainings.investmentHeading4'),
          description: t('trainings.investmentDesc4'),
          description2: t('trainings.investmentDesc42'),
          img: AssetHelper.staticPath('/static/home/trainings/preparing-for-working.jpg'),
        },
        {
          label: t('trainings.investmentHeading5'),
          description: t('trainings.investmentDesc5'),
          description2: t('trainings.investmentDesc52'),
          img: AssetHelper.staticPath('/static/home/trainings/safety-trainings.jpg'),
        },
        {
          label: t('trainings.investmentHeading6'),
          description: t('trainings.investmentDesc6'),
          description2: t('trainings.investmentDesc62'),
          img: AssetHelper.staticPath('/static/home/trainings/tutorials.jpg'),
        }
      ];

    return (
      <section className="trainings">
        <MetaTags>
          <title>{t("trainings.metaTitle")}</title>
          <meta name="description" content={t("trainings.metaDescription")} />
          <meta name="keywords" content={t("trainings.metaKeywords")} />
        </MetaTags>
        <section className="static-hero wrapper trainings">
          <div className="static-hero__content">
            <h1 className="static-hero__heading" dangerouslySetInnerHTML={{ __html: t('trainings.heading') }}></h1>
            <h5 className="static-hero__subheading" dangerouslySetInnerHTML={{ __html: t('trainings.subheading1') }}></h5>
            <h5 className="static-hero__subheading" dangerouslySetInnerHTML={{ __html: t('trainings.subheading2') }}></h5>
          </div>
        </section>
        <section className="corporate-use">
          <h2 className="corporate-use__heading">{t('trainings.corporateUse')}</h2>
          <div className="corporate-use__container">
            <div className="corporate-use__content">
              <div className="corporate-use__content-box">
                <img src={AssetHelper.staticPath('/static/home/trainings/ic_train_safely-svg.png')} className="corporate-use__content-box__icon" />
                <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.corporateUseDesc1')} } />
              </div>
              <div className="corporate-use__content-box">
                <img src={AssetHelper.staticPath('/static/home/trainings/ic_custom_made-svg.png')} className="corporate-use__content-box__icon" />
                <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.corporateUseDesc2')} } />
              </div>
              <div className="corporate-use__content-box">
                <img src="/static/home/trainings/ic_vr_training-svg.png" className="corporate-use__content-box__icon" />
                <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.corporateUseDesc3')} } />
              </div>
            </div>
            <img className="corporate-use__img" src={AssetHelper.staticPath('/static/home/trainings/img_section-training-solution.png')} alt="corporate use" />
          </div>
        </section>
        <LogotypeStrip trainings />
        <section className="how-to">
          <div className="how-to__box">
            <div className="img-responsive how-to__img">
              <img className="corporate-use__img" src={AssetHelper.staticPath('/static/home/trainings/img_section-vr-training.png')} alt="corporate use" />
            </div>
            <div className="how-to__box-content">
              <h2 className="how-to__box-heading">{t('trainings.forWhoHeading1')}</h2>
              <ul className="how-to__box-list">
                <li className="how-to__box-item">
                  <p className="list-item-heading how-to__item-heading how-to__item-heading--1-1">{t('trainings.forWhoBox1heading1')}</p>
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.forWhoBox1description1')} } />
                </li>
                <li className="how-to__box-item">
                  <p className="list-item-heading how-to__item-heading how-to__item-heading--1-2">{t('trainings.forWhoBox1heading2')}</p>
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.forWhoBox1description2')} } />
                  <br />
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.forWhoBox1description3')} } />
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="how-much">
          <div className="how-much__box">
            <div className="how-much__box-content">
              <h2 className="how-much__box-heading">{t('trainings.howMuch')}</h2>
              <ul className="how-much__box-list">
                <li className="how-much__box-item">
                  <p className="list-item-heading how-much__item-heading how-much__item-heading--1-1">{t('trainings.howMuchSubhead1')}</p>
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.howMuchDesc1')} } />
                </li>
                <li className="how-much__box-item">
                  <p className="list-item-heading how-much__item-heading how-much__item-heading--1-2">{t('trainings.howMuchSubhead2')}</p>
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.howMuchDesc2')} } />
                </li>
              </ul>
              <div className="btn" style={{ display: 'flex' }}>
                <a className="button button--larger cta__button" style={{ margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: t('trainings.pricing') }} href="/pricing" />
              </div>
            </div>
            <div className="img-responsive how-much__img">
              <img src={AssetHelper.staticPath('/static/home/trainings/img_section_cost.jpg')} style={{ maxWidth: 771 }} alt="vr" />
            </div>
          </div>


          {/*<div className="how-much__box">*/}
          {/*  <div className="how-much__content">*/}
          {/*    <h2 className="how-much__heading">{t('trainings.howMuch')}</h2>*/}
          {/*    <div className="how-much__content-box">*/}
          {/*      <p className="list-item-heading how-much__item-heading how-much__item-heading--1-1">{t('trainings.howMuchSubhead1')}</p>*/}
          {/*      <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.howMuchDesc1')} } />*/}
          {/*    </div>*/}
          {/*    <div className="how-much__content-box">*/}
          {/*      <p className="list-item-heading how-much__item-heading how-much__item-heading--1-2">{t('trainings.howMuchSubhead2')}</p>*/}
          {/*      <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.howMuchDesc2')} } />*/}
          {/*    </div>*/}
          {/*    <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('trainings.pricing') }} href="/pricing"></a>*/}
          {/*  </div>*/}
          {/*  <img className="how-much__img" src={AssetHelper.staticPath('/static/home/trainings/img_section_cost.jpg')} alt="VR" />*/}
          {/*</div>*/}
        </section>
        <section className="benefits">
          <div className="benefits__box">
            <Iframe url="https://evryplace.com/embed/spwgcq"
                    id="myId"
                    className="benefits__img"
                    display="initial"
                    frameBorder={0}
                    allowFullScreen
                    position="relative"/>
            <div className="benefits__box-content">
              <h2 className="benefits__box-heading">{t('trainings.benefitsHeading')}</h2>
              <ul className="benefits__box-list">
                <li className="benefits__box-item">
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.benefitsBox3description1')} } />
                </li>
                <br />
                <li className="benefits__box-item">
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.benefitsBox3description2')} } />
                </li>
                <br />
                <li className="benefits__box-item">
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.benefitsBox3description3')} } />
                </li>
                <br />
                <li className="benefits__box-item">
                  <p className="list-item-desc" dangerouslySetInnerHTML={{ __html: t('trainings.benefitsBox3description4')} } />
                </li>
                <br />
                <br />
                <li className="benefits__box-item">
                  <p className="list-item-desc"><i dangerouslySetInnerHTML={{ __html: t('trainings.benefitsBox3description5')} } /></p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="slogan">
          <h2 className="slogan__head">
            {t('trainings.findOutHeading')}
          </h2>
          <div>
            <a className="button button--larger button--cta slogan__button" onClick={() => this.props.showModal({modalType: ModalTypes.Contact})}>
              {t('pricing.businessModelButton')}
            </a>
          </div>
        </section>
        <section className="investment">
          <h2 className="investment__desktop-heading">{t('trainings.investmentHeading')}</h2>
          {hideTabs ? (
              <div className="investment__mobile">
                <Carousel items={carousel2Items}></Carousel>
              </div>
            ) : (
              <div className="investment__desktop tabs-container">
                <Tabs tabs={tabs}>
                  <TabsComponent />
                </Tabs>
              </div>
            )}
        </section>
      </section>
    );
  }
}

const {
  showModal,
} = uiActions;

const {
  getCurrentUser
} = authSelectors;

const mapStateToProps = state => ({
  user: getCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    showModal,
  }, dispatch),
});

export default translate('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Trainings));
