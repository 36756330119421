import React, { Component } from "react";
import "./trainingsSlideShow.scss";

class SlideShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curr: 0
    };
    this.updateImg = this.updateImg.bind(this);
  }
  componentDidMount() {
    setInterval(this.updateImg, 4500);
  }
  componentWillUnmount() {
    clearInterval(this.updateImg);
  }
  updateImg() {
    const { curr } = this.state,
      { images } = this.props,
      nextCurr = curr !== images.length - 1 ? curr + 1 : 0;
    this.setState({ curr: nextCurr });
  }
  render() {
    const { curr } = this.state,
      { images } = this.props,
      innerStyle = {
        top: (-100 * curr) + '%',
      };

    return (
      <div className="trainings-slide-show">
        <div className="slide-show__inner" style={innerStyle}>
          <div className="slide-show__item" >
            <img src={images[curr]} alt="phones picture" />
          </div>
        </div>
      </div>
    )
  }
}

export default SlideShow;
