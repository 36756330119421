import React, { Component } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const tabStyles = theme => ({
  tabsFull: {
    [theme.breakpoints.down('xl')] : {
      display: 'none'
    },
  },
  tabsRoot: {
    backgroundColor: '#f1f1f1',
    color: '#333',
    minHeight: '34px !important',
    height: '34px !important',
    borderRadius: '10px',
    fontFamily: theme.typography.fontFamily
  },
  tabsIndicator: {
    transform: 'translate(74px, -2px)',
    backgroundColor: 'transparent !important',
    width: '0 !important',
    height: '0 !important',
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid',
    borderTopColor: theme.palette.primary.main,
    transition: 'all 0.25s ease-in'
  },
  hide: {
    display: 'none'
  },
  tabRoot: {
    borderRight: '1px solid #b3b3b3',
    minHeight: '34px !important',
    height: '34px !important',
    textTransform: 'none'
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold'
  },
});

class HomeTabsTrainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }
  handleTabChange(e, value) {
    this.setState({ value });
  }
  render() {
    const { tabs, classes, children } = this.props;
    const { value, hideTabs } = this.state;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { val: value, label: tabs[value].heading, img: tabs[value].img, description: tabs[value].description, description2: tabs[value].description2 })
    );
    return (
      <div className="tabsFull">
        <div className="tabs" style={{width: `${tabs.length*194}px`, margin: '0 auto', position: 'relative'}}>
          {hideTabs || (
            <React.Fragment>
              <Tabs
                value={value} onChange={this.handleTabChange}
                classes={{ root: classes.tabsRoot, indicator: classes.hide }}
                style={{width: `${tabs.length*194}px`, margin: '0 auto'}}
              >
                {tabs.map((tab, i) => <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={tab.label} style={i === tabs.length - 1 ? { borderRight: 'none' } : {}} style={{ width: 194 }}/>)}
              </Tabs>
              <div className={classes.tabsIndicator} style={{position: 'absolute', top: '40px', left: `${(value*194)}px`, marginLeft: 17}}></div>
            </React.Fragment>
          )}
        </div>
        {childrenWithProps}
      </div>
    );
  }
}

export default withStyles(tabStyles)(HomeTabsTrainings);
