import React, {Component} from 'react';
import { translate } from "react-i18next";
import "./index.scss";
import BrowserImg from "home/icons/browser.svg";
import TourismCarousel from './components/tourismCarousel';
import TourismCarousel2 from './components/tourismCarousel2';
import AssetHelper from 'AssetHelper';

class RealEstate extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;

    const carouselItems = [
      {
        title: t("tourism.carousel1.heading1"),
        content: t("tourism.carousel1.desc1"),
        image: AssetHelper.staticPath('/static/home/tourism/carousel1/img-hotel1a.jpg'),
        image2: AssetHelper.staticPath('/static/home/tourism/carousel1/img-hotel1b.jpg'),
        id: 1
      },
      {
        title: t("tourism.carousel1.heading2"),
        content: t("tourism.carousel1.desc2"),
        image: AssetHelper.staticPath('/static/home/tourism/carousel1/img-hotel2a.jpg'),
        image2: AssetHelper.staticPath('/static/home/tourism/carousel1/img-hotel2b.jpg'),
        id: 2
      },
      {
        title: t("tourism.carousel1.heading3"),
        content: t("tourism.carousel1.desc3"),
        image: AssetHelper.staticPath('/static/home/tourism/carousel1/img-hotel3a.jpg'),
        image2: AssetHelper.staticPath('/static/home/tourism/carousel1/img-hotel3b.jpg'),
        id: 3
      }
    ];

    const carousel2Items = [
      {
        title: t("tourism.carousel2.heading1"),
        content: t("tourism.carousel2.desc1"),
        image: AssetHelper.staticPath('/static/home/tourism/carousel2/img-hotel1a.jpg'),
        image2: AssetHelper.staticPath('/static/home/tourism/carousel2/img-hotel1b.jpg'),
        id: 1
      },
      {
        title: t("tourism.carousel2.heading2"),
        content: t("tourism.carousel2.desc2"),
        image: AssetHelper.staticPath('/static/home/tourism/carousel2/img-hotel2a.jpg'),
        image2: AssetHelper.staticPath('/static/home/tourism/carousel2/img-hotel2b.jpg'),
        id: 2
      },
    ];

    return (
      <section className="tourism">
        <section className="static-hero wrapper">
          <div className="static-hero__content">
            <h1 className="static-hero__heading" dangerouslySetInnerHTML={{__html: t('tourism.heroHeading')}}></h1>
            <h5 className="static-hero__subheading" dangerouslySetInnerHTML={{__html: t('tourism.heroSubHeading')}}></h5>
          </div>
        </section>
        <section className="why-ep wrapper">
          <h2 className="why-ep__heading">{t('tourism.whyEpHeading')}</h2>
          <div className="why-ep__container">
            <BrowserImg className="svg" />
            <ul className="why-ep__list">
              <li className="why-ep__list-item">
                <p className="list-item-heading why-ep__item-heading strip">{t('tourism.item1Heading')}</p>
                <p className="list-item-desc why-ep__item-desc">{t('tourism.item1Desc')}</p>
              </li>
              <li className="why-ep__list-item">
                <p className="list-item-heading why-ep__item-heading strip">{t('tourism.item2Heading')}</p>
                <p className="list-item-desc why-ep__item-desc">{t('tourism.item2Desc')}</p>
              </li>
              <li className="why-ep__list-item">
                <p className="list-item-heading why-ep__item-heading strip">{t('tourism.item3Heading')}</p>
                <p className="list-item-desc why-ep__item-desc">{t('tourism.item3Desc')}</p>
              </li>
            </ul>
          </div>
        </section>
        <section className="tourism-carousel ep-for-hotels">
          <TourismCarousel items={carouselItems} />
        </section>
        <section className="tourism-carousel ep-for-events">
          <TourismCarousel2 items={carousel2Items} />
        </section>
        <section className="example">
          <h2 className="example__heading">{t('tourism.exampleHeading')}</h2>
          <div className="example__wrapper">
            <img className="example__img" src={AssetHelper.staticPath('/static/home/tourism/img-exampla-video.jpg')} />
            <div className="example__container wrapper">
              <img className="example__logo" src="static/home/home/logotype-set/spot.svg" alt="logo" />
              <h4 className="example__title">{t('tourism.exampleTitle')}</h4>
              <p className="example__description">{t('tourism.exampleDescription')}</p>
              <a className="example__button button button--larger" onClick={this.onRegisterClick}>
                {t('common.showMore')}
              </a>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default translate('translations')(RealEstate);
