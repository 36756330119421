const tabsComponent = props => (
  <div className="trainings-tabs">
    <div className="tabs-container__img investment__img">
      <img src={props.img} alt={props.label} />
    </div>
    <div className="tabs-container__content investment__content">
      <div className="dot" style={{width: 4, height: 4, backgroundColor: '#CCCCCC', marginTop: 7}}></div>
      <ul className="tabs-container__description investment__description" style={{ marginTop: -12 }} dangerouslySetInnerHTML={{ __html: props.description }}></ul>
      <div className="dot" style={{width: 4, height: 4, backgroundColor: '#CCCCCC', marginTop: 7}}></div>
      <ul className="tabs-container__description investment__description" style={{ marginTop: -12 }} dangerouslySetInnerHTML={{ __html: props.description2 }}></ul>
    </div>
  </div>
);

export default tabsComponent;
